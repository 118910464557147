<template>
  <div class="influencers-results">
    <!-- Show the search results table here -->
    <v-data-table
      :items="results"
      class="mt-4 box-shadow-soft"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th colspan="2">
              <!-- <div class="d-flex align-center pl-9">
                <span>
                  Sort By: &nbsp;
                </span>

                <div class="contain-select-width">
                  <v-select
                    v-model="sortBy"
                    :items="sortOptions"
                    hide-details
                    small
                    dense
                    dark
                    solo
                    flat
                    full-width
                    background-color="secondary"
                    :append-icon="null"
                  ></v-select>
                </div>
              </div> -->
            </th>

            <th>Followers</th>
            <th>Engagements</th>
            <!-- <th>Category</th> -->
            <th>Locations</th>
            <th>Languages</th>

            <th></th>
          </tr>
        </thead>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item.id"
            :class="{ 'selected-row': selectedRows[item.id] || item.added }"
          >
            <td class="pr-0">
              <div class="d-flex align-center justify-space-between">
                <span class="mr-3">
                  {{ index + 1 }}
                </span>

                <v-simple-checkbox
                  v-if="item.added"
                  disabled
                ></v-simple-checkbox>
                <v-simple-checkbox
                  v-else
                  color="primary"
                  :disabled="item.isLoading || item.added"
                  v-model="selectedRows[item.id]"
                ></v-simple-checkbox>
              </div>
            </td>

            <td>
              <profile-chip
                :platform="item.platform"
                :data="item.preview || { picture: null, username: item.username, fullname: item.name }"
              />
            </td>

            <td>
              {{ nFormatter(item.followers) }}
            </td>

            <td>
              {{ nFormatter(item.engagement_rate) }}%
            </td>

            <!-- <td>
              {{ item.category }}
            </td> -->

            <td>
              {{ item.locations.join(", ") }}
            </td>

            <td>
              {{ item.languages.join(", ") }}
            </td>

            <td>
              <div class="d-flex align-center justify-end">
                <!-- To show interests list -->
                <v-btn
                  text
                  depressed
                  color="secondary"
                  @click="selectedItem = item; shouldShowInterestsDialog = true;"
                >
                  <v-icon>
                    interests
                  </v-icon>
                </v-btn>

                <!-- To show cost details -->
                <v-btn
                  text
                  depressed
                  color="secondary"
                  @click="selectedItem = item; shouldShowCostsDialog = true;"
                >
                  <v-icon>
                    monetization_on
                  </v-icon>
                </v-btn>

                <!-- To push this item to the campaign plan -->
                <!-- <v-btn
                  text
                  color="primary"
                  @click="handleAddToPlan(item)"
                  :loading="item.isLoading"
                  :disabled="item.isLoading || item.added"
                  depressed
                >
                  <v-icon>
                    {{ item.added ? 'done' : 'add_circle' }}
                  </v-icon>
                </v-btn> -->
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <!-- Show the dialog with item costs -->
    <v-dialog
      v-model="shouldShowCostsDialog"
      max-width="300"
    >
      <v-card>
        <v-card-title>
          Influencer Cost Details
        </v-card-title>

        <v-card-text v-if="selectedItem">
          <v-list dense>
            <template
              v-for="(keyString, index) in Object.keys(costKeyMap[selectedItem.platform])"
            >
              <v-list-item
                :key="keyString"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon color="secondary">
                    {{ costKeyIconMap[keyString] }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="d-flex justify-space-between">
                    <span>
                      {{ costKeyMap[selectedItem.platform][keyString] }}
                    </span>
                    <span class="font-weight-bold">
                      {{ currencySymbolMap[selectedItem.currency] }}{{ nFormatter(selectedItem[keyString]) }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider v-if="index < Object.keys(costKeyMap[selectedItem.platform]).length - 1"></v-divider>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Show the dialog for list of influencer interests -->
    <v-dialog
      v-model="shouldShowInterestsDialog"
      max-width="300"
      scrollable
    >
      <v-card>
        <v-card-title>
          Influencer Interests
        </v-card-title>

        <v-card-text v-if="selectedItem">
          <v-list dense>
            <template
              v-for="(interest, index) in selectedItem.interests"
            >
              <v-list-item
                :key="interest + index"
                class="px-0"
              >
                <v-list-item-title>
                  {{ interest }}
                </v-list-item-title>
              </v-list-item>

              <v-divider v-if="index < selectedItem.interests.length - 1"></v-divider>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Show a FAB when rows are selected -->
    <v-fab-transition>
      <v-btn
        v-show="shouldShowFAB"
        @click="handleSubmit"
        color="primary"
        fixed
        bottom
        right
        dark
        fab
      >
        <v-icon>
          add
        </v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- In case we need to select a plan first -->
    <v-dialog
      v-model="shouldShowSelectDialog"
      max-width="500"
      :persistent="isCreatePlanMakingRequest"
    >
      <v-card>
        <v-card-title>
          Select a campaign plan
        </v-card-title>

        <v-card-text>
          <v-combobox
            v-model="selectedPlan"
            :items="campaignPlans"
            label="Select or create new"
            dense
            eager
            outlined
            return-object
            hide-details
            item-text="name"
            item-value="id"
            full-width
          ></v-combobox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="handleCreateCampaignPlan"
            :disabled="isCreatePlanMakingRequest"
            :loading="isCreatePlanMakingRequest"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @BUGFIX https://github.com/vuetifyjs/vuetify/issues/12224#issuecomment-894201049
import Ripple from 'vuetify/lib/directives/ripple'

// Import helper objects
import costKeyMap from "@/helpers/costKeyMap.js"
import costKeyIconMap from "@/helpers/costKeyIconMap.js"
import currencySymbolMap from "@/helpers/currencySymbolMap"

// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip.vue")

// Export the SFC
export default {
  // Name of the component
  name: "ResultsTable",

  // Register the directive objects
  directives: {
    Ripple,
  },

  // Register children components
  components: {
    ProfileChip
  },

  // The incoming data from parent component
  props: {
    results: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    // If selected item is there, show a dialog for it
    selectedItem: null,
    shouldShowCostsDialog: false,
    shouldShowInterestsDialog: false,

    callbackFunction: null,
    shouldShowSelectDialog: false,
    isCreatePlanMakingRequest: false,

    costKeyMap,
    costKeyIconMap,
    currencySymbolMap,

    selectedRows: {},

    selectedPlan: null,

    // Used in the table view
    sortBy: "followers",
    sortOptions: [
      {
        text: "Followers",
        value: "followers"
      },
      {
        text: "Engagements",
        value: "engagements"
      }
    ],
  }),

  computed: {
    /**
     * Return a list of campaign plans for the selector
     *
     * @returns {Array}
     */
    campaignPlans() {
      return this.$store.getters["campaignPlanning/items"]
    },

    /**
     * Map the rows that were selected to their object in the results array
     */
    selectedItems() {
      return Object.entries(this.selectedRows)
        // filter out rows with values "true"
        .filter((item) => item[1])
        // for each of them, compare with the key
        .map((item) => this.results.find((search) => search.id === Number(item[0])))
        // make sure that the items are not selected/added already
        .filter((item) => !item.added)
    },

    /**
     * Whether or not to show the floating action button
     */
    shouldShowFAB() {
      return this.selectedItems.length > 0
    }
  },

  watch: {
    /**
     * Watch for any changes in the sort by select input's value
     *
     * @param {String} oldValue
     * @param {String} newValue
     */
    sortBy(oldValue, newValue) {
      // If value wasn't changed, don't execute further
      if (oldValue === newValue) return

      // If the value was changed, reload the results
      this.emitSearch()
    }
  },

  methods: {
    /**
     * Trigger a popup to select the campaign plan
     *
     * @param {Function} callback - The function to callback once we've done selecting the plan
     * @returns {void}
     */
    selectPlan(callback) {
      // Check if we need to show the dialog
      if (this.$route.name === "CampaignPlanningSearch") {
        this.shouldShowSelectDialog = true
        this.callbackFunction = callback
      }
      // Otherwise call the callback function
      else {
        callback()
      }
    },

    /**
     * Push a single row item without selecting it
     *
     * @returns {void}
     */
    handleAddToPlan(item) {
      // Pass the function to call when selected
      this.selectPlan(() => {
        this.$emit("addToPlan", item, this.selectedPlan?.id)
      })
    },

    /**
     * When rows were selected, and the FAB button is clicked
     *
     * @returns {void}
     */
    handleSubmit() {
      // Pass the function to call when selected
      this.selectPlan(() => {
        for (const item of this.selectedItems) {
          this.$emit('addToPlan', item, this.selectedPlan?.id)
        }
      })
    },

    /**
     * Handle submit event from campaign plans select dialog
     *
     * @return void
     */
    handleCreateCampaignPlan() {
      // make sure the dialog is not closeable
      this.isCreatePlanMakingRequest = true

      // since vuetify's combo-box is a bit slow, wait for a second
      setTimeout(async () => {
        // if the value is empty
        if (this.selectedPlan === null || this.selectedPlan === "") {
          this.$store.dispatch("toasts/add", { text: "Please enter a name for the campaign plan" })
          this.isCreatePlanMakingRequest = false

          return false
        }

        // Otherwise check if the value is a string
        if (typeof this.selectedPlan === "string") {
          // Try making the campaign plan model
          try {
            // If yes, then we'd need to create a campaign first
            const response = await axios({
              url: "/api/campaign-plans",
              method: "POST",
              data: {
                name: this.selectedPlan
              }
            })

            // Show a message to the user
            this.$store.dispatch("toasts/add", { text: "Campaign plan created!" })
            this.selectedPlan = response.data

            // Also fetch campaign plans for the selector
            this.$store.dispatch("campaignPlanning/fetchItems")
          } catch(error) {
            logger({ type: "Could not create new campaign plan", error })

            this.$store.dispatch("toasts/add", { text: "Could not create new campaign plan" })
          }
        }

        // Once they're done, check if we have a campaign plan
        if (typeof this.selectedPlan === "object" && typeof this.callbackFunction === "function") {
          // Call the callback function
          this.callbackFunction()
        }

        // reset the local state
        this.selectedPlan = null
        this.callbackFunction = null
        this.shouldShowSelectDialog = false
        this.isCreatePlanMakingRequest = false
      }, 1000)
    }
  },

  // When the component is ready
  mounted() {
    // Fetch campaign plans for the selector
    this.$store.dispatch("campaignPlanning/fetchItems")
  }
}
</script>

<style lang="stylus">
.influencers-results
  .contain-select-width
    width 12em

    .v-select
      font-size 1.1em

  .selected-row
    background-color rgba(0, 0, 0, 0.1)
</style>
